<template>
  <PromoLayout :loading="loading">
    <PageHeading
      :inline="isFromDashboard"
      :title="title"
      :subtitle="subtitle"
    />
    <div class="u-margin-bottom-small">
      <AppStepSection
        title="Select Technology"
        :checked="technologySelected"
        :required="!technologySelected"
      >
        <TechnologySelector v-model="selectedTechnology" />
      </AppStepSection>
    </div>
    <div class="u-margin-bottom-small">
      <AppStepSection
        title="Select Production Options"
        :title-inactive="!technologySelected"
        :checked="productionOptionsSuccess"
      >
        <div class="production-options">
          <div
            class="production-options__item"
            :class="{
              'production-options__item--disabled': !technologySelected,
            }"
          >
            <ItarSelect
              :itar-option="itarOption"
              :show-button="true"
              :technology-selected="technologySelected"
              @change="onChangeItarOption"
            />
          </div>
          <div
            class="production-options__item"
            :class="{
              'production-options__item--disabled':
                !technologySelected || overseasDisabled,
            }"
          >
            <OverseasProductionAcceptable
              :overseas-option="overseasOption"
              :disabled="!technologySelected || overseasDisabled"
              @change="onChangeOverseasOption"
            />
          </div>
        </div>
      </AppStepSection>
    </div>
    <div v-if="!isSheetMetal" class="u-margin-bottom-small">
      <AppStepSection
        title="Choose File(s) for a Quote"
        :title-inactive="!technologySelected || !productionOptionsSuccess"
        :checked="allFilesUploaded"
      >
        <FileUploader
          :files="files"
          :accepted-formats="acceptedFormats"
          :technology-selected="!!technologySelected"
          :upload-again="uploadAgain"
          :technology-id="technologyId"
          :disabled="!technologySelected || !productionOptionsSuccess"
          @uploadRequest="onFilesUploadRequest"
          @fileRemove="onFileRemove"
          @fileStatusChanged="onFileStatusChanged"
          @reject="onReject"
          @fileUnitsChanged="onFileUnitsChanged"
        />
      </AppStepSection>
    </div>
    <div v-else class="u-margin-bottom-small">
      <AppStepSection title="Choose File(s) for a Quote">
        <div class="file-uploader file-uploader--full-height">
          <div class="file-uploader-placeholder">
            <p class="paragraph">
              <a
                class="button-primary link--no-decoration"
                target="_blank"
                href="https://fathommfg.com/get-a-sheet-metal-quote"
              >
                <span class="link--no-decoration">
                  <strong>GO TO SHEET METAL REQUEST FORM</strong>
                </span>
              </a>
            </p>
          </div>
        </div>
      </AppStepSection>
    </div>
    <div v-if="manualQuote" class="u-margin-bottom-small">
      <AppStepSection title="Additional Description">
        <div class="u-margin-bottom-small">
          <InputText
            v-model="description"
            :textarea="true"
            label="Your request is nearly complete. Please provide additional details to accurately process your quote."
            placeholder="Can include material, quantity, finish, inspection requirements, etc"
          />
        </div>
        <div class="u-margin-bottom-small">
          <InputText
            v-model="phoneNumber"
            :mask="phoneMask"
            label="Phone number"
            width="26rem"
            :show-errors="submitted"
            :errors="validations.errors.phoneNumber"
          />
        </div>
      </AppStepSection>
    </div>
    <div v-if="!isSheetMetal" class="u-margin-bottom-small">
      <InputCheckbox v-model="acknowledgement" :disabled="isSheetMetal"
        ><span class="paragraph--grey paragraph--align"
          >Fathom is unable to guarantee any build that has walls or features
          &lt;1.0mm. While some processes are able to successfully build parts
          with walls or features that fall below that threshold, the issue is
          post-processing, where the part may be exposed to high-pressure air,
          water or another process. We’ve found that walls and features that
          fall below the 1.0mm threshold have a high rate of failure</span
        ></InputCheckbox
      >
    </div>
    <div v-if="!isSheetMetal" class="u-margin-bottom-small">
      <div class="main-actions">
        <div class="main-actions__action">
          <div class="main-actions__btn">
            <ButtonPrimary
              @click="submit"
              :id="idRequestButton"
              :disabled="isSheetMetal || submitDisabled"
            >
              {{ actionName }}
            </ButtonPrimary>
            <AppTooltip v-if="submitDisabled" :full-width="true" position="top">
              <template v-if="!technologySelected">
                Please select your process to proceed
              </template>
              <template v-else-if="!allFilesWithAcceptedFormats">
                Some of your files are unacceptable file types
              </template>
              <template v-else-if="!allFilesWithAsciiName">
                File character format isn't accepted. Please rename the file to
                an ASCII formatting.
              </template>
              <template v-else-if="!isAllDimensionFilled">
                Please specify unit type for uploaded files.
              </template>
              <template v-else-if="!itarCheckSuccess">
                Cannot process ITAR files - Please confirm files are non-ITAR
              </template>
              <template v-else-if="!allFilesUploaded">
                Please upload your parts to proceed
              </template>
              <template v-else-if="!acknowledgement">
                Please review and agree with conditions above to proceed
              </template>
            </AppTooltip>
          </div>

          <div v-if="errorMessage" class="main-actions__error">
            <p class="paragraph paragraph--red">
              {{ errorMessage }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </PromoLayout>
</template>

<script>
import { mapGetters } from 'vuex'

import { phone } from '@/core/plugins/validator/validators'
import { createManualQuote, createInstantQuote } from '@/api/quotesApi'
import { getErrorMessage } from '@/core/helpers/error'
import { isASCII } from '@/core/utils/common'

import { FileStatuses, TechnologyTypes } from '@/core/utils/constants'
import { PHONE_MASK } from '@/core/utils/masks'
import { acceptedFormats } from '@/core/helpers/accepted-formats'
import { extDimensionRequired } from '@/core/utils/constants'

import AppStepSection from '@/core/components/content/AppStepSection'
import AppTooltip from '@/core/components/content/AppTooltip'
import ButtonPrimary from '@/core/components/buttons/ButtonPrimary'
import InputText from '@/core/components/inputs/InputText'
import PageHeading from '@/core/components/content/PageHeading'

import PromoLayout from '@/main/layouts/PromoLayout'
import FileUploader from '@/core/components/controls/file-uploader/FileUploader'
import TechnologySelector from '@/core/components/controls/TechnologySelector'
import InputCheckbox from '@/core/components/inputs/InputCheckbox'
import ItarSelect from '@/core/components/controls/ItarSelect'
import OverseasProductionAcceptable from '../../core/components/controls/OverseasProductionAcceptable.vue'

const validators = {
  phoneNumber: [phone()],
}

export default {
  name: 'MainPage',
  components: {
    OverseasProductionAcceptable,
    ItarSelect,
    InputCheckbox,
    AppStepSection,
    AppTooltip,
    ButtonPrimary,
    InputText,
    PromoLayout,
    FileUploader,
    PageHeading,
    TechnologySelector,
  },
  data() {
    return {
      files: [],
      selectedTechnology: null,
      description: '',
      phoneNumber: '',
      phoneMask: PHONE_MASK,
      submitted: false,
      loading: false,
      errorMessage: '',
      isFromDashboard: false,
      isRejectUploading: false,
      acknowledgement: false,
      partsUnit: '',
      uploadAgain: false,
      itarOption: null,
      overseasOption: null,
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.isFromDashboard = to.params.isFromDashboard === 'true'
    })
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn', 'user']),
    validations() {
      const data = {}

      if (this.manualQuote) {
        data.phoneNumber = this.phoneNumber
      }

      return this.$validator.validate(data, validators)
    },
    subtitle() {
      return this.isFromDashboard && this.isLoggedIn
        ? `Hello ${this.user.firstName}, `
        : 'Welcome to the Fathom Manufacturing Cost Calculator'
    },
    title() {
      return this.isFromDashboard && this.isLoggedIn
        ? 'Start Your Next Fathom Quote Here'
        : 'Get A Quote'
    },
    submitDisabled() {
      if (this.loading) return true
      if (!this.allFilesUploaded) return true
      if (!this.allFilesWithAcceptedFormats) return true
      if (!this.acknowledgement) return true
      if (!this.isAllDimensionFilled) return true
      if (!this.allFilesWithAsciiName) return true
      if (!this.itarCheckSuccess) return true

      return !this.technologySelected
    },
    uploadedFiles() {
      return this.files.filter(file => file.status === FileStatuses.UPLOADED)
    },
    allFilesUploaded() {
      return (
        this.files.length > 0 && this.uploadedFiles.length === this.files.length
      )
    },
    acceptedFormats() {
      return acceptedFormats({
        is3D: this.printingTechnologySelected,
        isSheetMetal: this.isSheetMetal,
      })
    },
    allFilesWithAcceptedFormats() {
      return (
        this.files.filter(file =>
          this.acceptedFormats.find(
            format =>
              file.original.name.toLowerCase().indexOf(`.${format}`) !== -1
          )
        ).length === this.files.length
      )
    },
    allFilesWithAsciiName() {
      return (
        this.files.filter(file => isASCII(file.original.name)).length ===
        this.files.length
      )
    },
    technologySelected() {
      return !!this.selectedTechnology
    },
    printingTechnologySelected() {
      return (
        this.selectedTechnology &&
        this.selectedTechnology.id === TechnologyTypes.Printing
      )
    },
    isRequestQuoteTechnology() {
      if (this.selectedTechnology) {
        return [
          TechnologyTypes.CNCMachining,
          TechnologyTypes.UrethaneCasting,
          TechnologyTypes.InjectionMolding,
          TechnologyTypes.SheetMetal,
          TechnologyTypes.MultipleProcesses,
        ].includes(this.selectedTechnology.id)
      }

      return false
    },
    isSheetMetal() {
      if (this.selectedTechnology) {
        return [TechnologyTypes.SheetMetal].includes(this.selectedTechnology.id)
      }
      return false
    },
    actionName() {
      return this.manualQuote || this.isRequestQuoteTechnology
        ? 'Request a Quote'
        : 'Get instant Quote Now'
    },
    manualQuote() {
      if (this.selectedTechnology) {
        return [TechnologyTypes.MultipleProcesses].includes(
          this.selectedTechnology.id
        )
      }

      return false
    },
    idRequestButton() {
      if (this.manualQuote) {
        return 'requestquotehybrid'
      } else {
        return undefined
      }
    },
    isAllDimensionFilled() {
      const needDimension = this.uploadedFiles.filter(file => {
        const fileParts = file.original.name.split('.')
        const extension = fileParts[fileParts.length - 1]
        return extDimensionRequired.includes(extension.toLowerCase())
      })
      const setupUnits = needDimension.filter(item => item.units)
      return needDimension.length === setupUnits.length
    },
    technologyId() {
      return this.selectedTechnology ? this.selectedTechnology.id : null
    },
    itarCheckSuccess() {
      return this.itarOption === 'no'
    },
    overseasDisabled() {
      return (
        this.printingTechnologySelected ||
        this.isSheetMetal ||
        this.itarOption === 'yes'
      )
    },
    productionOptionsSuccess() {
      let result = this.itarCheckSuccess
      if (!this.overseasDisabled) {
        if (!this.overseasOption) result = false
      }
      return result
    },
  },
  mounted() {
    if (this.$route.query.reset_password) {
      if (this.$route.query.email && this.$route.query.token) {
        this.$vfm.show('reset-password', {
          email: this.$route.query.email,
          token: this.$route.query.token,
        })
      }
    }
  },
  watch: {
    selectedTechnology() {
      this.errorMessage = ''
    },
  },
  methods: {
    forceLogin() {
      if (!this.isLoggedIn) {
        this.$vfm.show('auth', {
          closable: true,
          uploadingFiles: true,
          onSuccessLogin: () => {
            this.uploadAgain = true
          },
        })
      }

      return this.isLoggedIn
    },
    onFilesUploadRequest(files) {
      files.forEach(file => this.files.push(file))

      if (!this.isRejectUploading) this.forceLogin()
    },
    onFileRemove(id) {
      const index = this.files.findIndex(file => file.id === id)

      if (index !== -1) {
        this.files.splice(index, 1)
      }
    },
    onFileStatusChanged({ id, status, partId }) {
      const index = this.files.findIndex(file => file.id === id)

      if (index !== -1) {
        this.files[index].status = status

        if (partId) {
          this.files[index].partId = partId
        }
      }
    },
    onReject(value) {
      this.isRejectUploading = value
    },
    async submit() {
      if (!this.forceLogin(this.files)) return

      if (!this.technologySelected) {
        this.errorMessage = 'Please select a technology'
      } else if (!this.files.length) {
        this.errorMessage = 'Please upload parts'
      } else if (!this.allFilesWithAcceptedFormats) {
        this.errorMessage =
          'STLs and OBJs are not acceptable file types for this technology'
      } else if (!this.acknowledgement) {
        this.errorMessage = 'You should agree with conditions above'
      } else if (!this.isAllDimensionFilled) {
        this.errorMessage = 'Please specify units in files'
      }

      setTimeout(() => {
        this.errorMessage = ''
      }, 5000)

      if (this.submitDisabled) return

      this.submitted = true

      if (!this.validations.isValid || this.loading) return

      this.loading = true

      try {
        const payload = {
          parts: this.files.map(file => ({
            partId: file.partId,
            units: file.units,
          })),
          technologyId: this.selectedTechnology.id,
          technologyName: this.selectedTechnology.title,
          phone: this.phoneNumber || this.user.phoneNumber,
          email: this.user.email,
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          overseasProductionAcceptable: this.overseasOption,
        }

        if (this.manualQuote) {
          const { data } = await createManualQuote({
            ...payload,
            notes: this.description,
          })
          this.$vfm.show('success', {
            type: 'quote-requested',
            quoteId: data.id,
            isInjectionMolding:
              this.selectedTechnology.id === TechnologyTypes.InjectionMolding,
            isPrintingTechnology: this.printingTechnologySelected,
            isSheetMetal: this.isSheetMetal,
            closable: true,
            onClose: () => {
              this.$router.push({
                name: 'dashboard',
                params: { itemsType: 'quotes' },
              })
            },
          })
        } else {
          const { data } = await createInstantQuote({
            ...payload,
          })
          await this.$router.push({
            name: 'quote-summary',
            params: { id: data.id },
          })
        }
      } catch (err) {
        this.errorMessage = getErrorMessage(err)
        console.log(`submit error:${this.errorMessage}`)
      } finally {
        this.loading = false
      }
    },
    onFileUnitsChanged({ id, units }) {
      const index = this.files.findIndex(file => file.id === id)
      if (index !== -1) {
        this.files[index].units = units
      }
    },
    onChangeItarOption(value) {
      this.itarOption = value
    },
    onChangeOverseasOption(value) {
      this.overseasOption = value
    },
  },
}
</script>
